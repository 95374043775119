<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <template
            slot="body"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>{{ $t('pageKey416', '商品信息') }}</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey204', '商品名称')+':'"
                                label-width="11em"
                            >
                                {{ goods.goodsName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey417', '商品简称')+':'"
                                label-width="11em"
                            >
                                {{ goods.goodsShortName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey237', '商品副标题')+':'"
                                label-width="11em"
                            >
                                {{ goods.goodsSubtitle }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey202', '商品货号')+':'"
                                label-width="11em"
                            >
                                {{ goods.goodsSn }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                :label="$t('pageKey206', '商品分类')+':'"
                                label-width="11em"
                            >
                                {{ goods.goodsCatNamePath }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                :label="$t('pageKey215', '商品图片')+':'"
                                label-width="11em"
                            >
                                <image-list :data="goods.goodsImageList"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                :label="$t('pageKey240', '商品详情图')+':'"
                                label-width="11em"
                            >
                                <image-list :data="goods.goodsDetailImageList"/>
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="24"
                            v-if="!!goods.skuList && !!goods.skuList[0].combinationGoodsList">
                            <el-form-item
                                :label="$t('pageKey287', '组合商品列表')+':'"
                                label-width="11em"
                            >
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    :data="goods.skuList[0].combinationGoodsList"
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        :label="$t('pageKey215', '商品图片')"
                                        width="120"
                                    >
                                        <template slot-scope="scope">
                                            <image-list :data="scope.row.specImageUrl"/>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="goodsName"
                                        :label="$t('pageKey204', '商品名称')"
                                        min-width="130"
                                    />
                                    <el-table-column
                                        prop="goodsSn"
                                        :label="$t('pageKey202', '商品编码')"
                                        min-width="80"
                                    />
                                    <el-table-column
                                        prop="skuSn"
                                        :label="$t('pageKey242', 'sku编码')"
                                        min-width="80"
                                    />
                                    <el-table-column
                                        prop="specDesc"
                                        :label="$t('pageKey254', '商品规格')"
                                        min-width="80"
                                    />
                                    <el-table-column
                                        prop="combinationQuantity"
                                        :label="$t('pageKey418', '商品数量')"
                                        width="70"
                                    />
                                </el-table>
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>{{ $t('pageKey419', 'sku列表') }}</span>
                    </div>
                    <div class="item">
                        <el-col :span="24">
                            <el-form-item
                                prop="paymentMethod"
                                :label="$t('pageKey171', '付款方式')"
                                label-width="11em"
                            >
                                <checkbox-group-andOr
                                    type="paymentMethod"
                                    v-model="formModel.paymentMethod"
                                />
                            </el-form-item>
                            <el-form-item>
                                <!-- 表格 -->
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    :data="skuList"
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        :label="$t('pageKey420', '规格图片')"
                                        width="135"
                                    >
                                        <template slot-scope="scope">
                                            <image-list :data="scope.row.specImageUrl"/>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="skuSn"
                                        :label="$t('pageKey242', 'sku编码')"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="specDesc"
                                        :label="$t('pageKey254', '商品规格')"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="platPrice"
                                        :label="$t('pageKey257', '平台价格')"
                                        width="105"
                                    />
                                    <el-table-column
                                        prop="showStock"
                                        :label="$t('pageKey218', '库存')"
                                        width="80"
                                    />
                                    <el-table-column
                                        prop="price"
                                        :label="$t('pageKey243', '价格')"
                                        min-width="70"
                                        v-if="(formModel.paymentMethod & 1) !== 0"
                                    >
                                        <template slot-scope="scope">
                                            <el-input-number
                                                controls-position="right"
                                                :min="0.01"
                                                :precision="2"
                                                v-model.number="scope.row.price"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="pointNum"
                                        :label="$t('pageKey258', '积分')"
                                        min-width="70"
                                        v-if="(formModel.paymentMethod & 2) !== 0"
                                    >
                                        <template slot-scope="scope">
                                            <el-input-number
                                                controls-position="right"
                                                :min="1"
                                                :precision="0"
                                                v-model.number="scope.row.pointNum"
                                            />
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                            <el-form-item>
                                <span
                                    class="m-t"
                                >
                                    <span class="batch">{{ $t('pageKey244', '批量设置') }}: </span>
                                    <template v-for="(item,index) in formField">
                                        <el-button
                                            :key="index"
                                            v-if="!item.hide"
                                            @click="onBatch(item)"
                                        >
                                            {{ item.name }}
                                        </el-button>
                                    </template>
                                </span>
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>{{ $t('pageKey421', '代理设置') }}</span>
                    </div>
                    <div class="item">
                        <el-col :span="24">
                            <el-form-item
                                prop="goodsName"
                                :label="$t('pageKey204', '商品名称')"
                                label-width="11em"
                            >
                                <el-input
                                    v-model="formModel.goodsName"
                                    auto-complete="off"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="goodsImage"
                                :label="$t('pageKey215', '商品列表图')"
                                label-width="11em"
                            >
                                <el-button
                                    type="success"
                                    size="small"
                                    @click="syncGoodsImage"
                                >
                                    {{ $t('pageKey422', '同步平台商品图片') }}
                                </el-button>
                                <upload
                                    class="ma-t"
                                    draggable
                                    validate-md5
                                    action="/rs/attachment/uploadFileLibrary"
                                    limit="12"
                                    :tips="$t('pageKey239', '建议尺寸：800*800像素,最多上传12张图片')"
                                    v-model="formModel.goodsImage"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="goodsDetailImage"
                                :label="$t('pageKey240', '商品详情图')"
                                label-width="11em"
                            >
                                <el-button
                                    type="success"
                                    size="small"
                                    @click="syncGoodsDetailImage"
                                >
                                    {{ $t('pageKey423', '同步平台商品详情图') }}
                                </el-button>
                                <upload
                                    class="ma-t"
                                    draggable
                                    validate-md5
                                    action="/rs/attachment/uploadFileLibrary"
                                    :limit="12"
                                    :tips="$t('pageKey239', '建议尺寸：800*800像素,最多上传12张图片')"
                                    v-model="formModel.goodsDetailImage"
                                />
                            </el-form-item>
                            <!--                            <el-form-item
    prop="storeInitGoodsCatId"
    label="门店初始商品类目"
    label-width="11em"
>
    <RemoteSelect
        multiple
        remote="/sp/storeInitGoodsCat/select"
        placeholder="请输入"
        :props="{id:'id',name:'goodsCatName'}"
        v-model="formModel.storeInitGoodsCatId"
    />
</el-form-item>-->
                            <el-form-item
                                prop="shopGoodsCatIds"
                                :label="$t('pageKey207', '店铺类目')"
                                label-width="11em"
                                v-if="(formModel.paymentMethod & 1) !== 0"
                            >
                                <TreePicker
                                    remote="/sp/shopGoodsCat/selectAll"
                                    v-model="formModel.shopGoodsCatIds"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="pointGoodsCatIds"
                                :label="$t('pageKey424', '积分商品类目')"
                                label-width="11em"
                                v-if="(formModel.paymentMethod & 2) !== 0"
                            >
                                <TreePicker
                                    remote="/sp/pointGoodsCat/selectAll"
                                    v-model="formModel.pointGoodsCatIds"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="shopTagId"
                                :label="$t('pageKey327', '商品分组')"
                                label-width="11em"
                            >
                                <RemoteSelect
                                    multiple
                                    remote="/sp/shopTag/select"
                                    :placeholder="$t('pageKey238', '请输入')"
                                    :props="{id:'id',name:'tagName'}"
                                    v-model="formModel.shopTagId"
                                />
                            </el-form-item>
                            <el-form-item
                                prop="taxRate"
                                :label="$t('pageKey409', '税率')"
                                label-width="11em"
                            >
                                <el-select
                                    v-model="formModel.taxRate"
                                    :placeholder="$t('pageKey101', '请选择')"
                                >
                                    <el-option
                                        key="0"
                                        label="0%"
                                        :value="0"
                                    />
                                    <el-option
                                        key="5"
                                        label="6.3%"
                                        :value="6.3"
                                    />
                                    <el-option
                                        key="1"
                                        label="9.1%"
                                        :value="9.1"
                                    />
                                    <el-option
                                        key="4"
                                        label="17.9%"
                                        :value="17.9"
                                    />
                                    <el-option
                                        key="2"
                                        label="23.1%"
                                        :value="23.1"
                                    />
                                    <el-option
                                        key="3"
                                        label="29%"
                                        :value="29"
                                    />
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                prop="pointRate"
                                :label="$t('pageKey425', '积分获取比例')"
                                label-width="11em"
                            >
                                <el-input-number
                                    controls-position="right"
                                    :min="0"
                                    :precision="2"
                                    v-model.number="formModel.pointRate"
                                />
                                {{ $t('pageKey426', '1元=？积分') }}<span v-if="!!defaultPointRate">
                                    {{ $t('pageKey427', '(为空的时候使用默认配置，1元=') }}{{
                                        defaultPointRate
                                    }}{{ $t('pageKey428', '积分)') }}
                                </span>
                            </el-form-item>
                            <el-form-item
                                prop="orderNum"
                                :label="$t('pageKey11', '排序')"
                                label-width="11em"
                            >
                                <el-input-number
                                    controls-position="right"
                                    :min="1"
                                    v-model.number="formModel.orderNum"
                                />
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                    :header="$t('pageKey315', '分销设置')"
                >
                    <el-form-item
                        prop="shopIncomeRate"
                        :label="$t('pageKey410', '商户级分佣比例')"
                        label-width="11em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :max="50"
                            :precision="2"
                            v-model.number="formModel.shopIncomeRate"
                        />
                    </el-form-item>
                    <!--                    <el-form-item
prop="shareIncomeRate"
label="分销分享赚比例(%)"
label-width="11em"
v-if="userData.shop && (1 & userData.shop.distributionIncomeMode) !== 0"
>
<el-input-number
controls-position="right"
:min="0"
:precision="2"
v-model.number="formModel.shareIncomeRate"
/>
</el-form-item>
<el-form-item
prop="topIncomeRate"
label="分销顶级提成比例(%)"
label-width="11em"
v-if="userData.shop && (2 & userData.shop.distributionIncomeMode) !== 0"
>
<el-input-number
controls-position="right"
:min="0"
:precision="2"
v-model.number="formModel.topIncomeRate"
/>
</el-form-item>
<el-form-item
prop="levelIncomeRate"
label="分销层级提成比例"
label-width="11em"
v-if="userData.shop && (4 & userData.shop.distributionIncomeMode) !== 0 || (8 & userData.shop.distributionIncomeMode) !== 0"
>
<el-table
stripe
border
size="small"
:data="levelIncomeRateList"
style="width: 100%"
>
<el-table-column
prop="name"
label="分销层级"
width="120"
/>
<el-table-column
prop="levelIncomeRate"
label="提成比例(%)"
min-width="220"
>
<template slot-scope="scope">
<el-input-number
controls-position="right"
:min="0"
:precision="2"
v-model.number="scope.row.levelIncomeRate"
/>
</template>
</el-table-column>
</el-table>
</el-form-item>
<el-form-item
prop="liveShareIncomeRate"
label="直播分享赚比例(%)"
label-width="11em"
v-if="userData.shop && (16 & userData.shop.distributionIncomeMode) !== 0"
>
<el-input-number
controls-position="right"
:min="0"
:precision="2"
v-model.number="formModel.liveShareIncomeRate"
/>
</el-form-item>
<el-form-item
prop="anchorIncomeRate"
label="主播提成比例(%)"
label-width="11em"
v-if="userData.shop && (32 & userData.shop.distributionIncomeMode) !== 0"
>
<el-input-number
controls-position="right"
:min="0"
:precision="2"
v-model.number="formModel.anchorIncomeRate"
/>
</el-form-item>-->
                </el-card>
                <el-card
                    class="ma-t">
                    <el-form-item
                        label-width="13em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            {{ $t('pageKey73', '保存') }}
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            {{ $t('pageKey126', '返 回') }}
                        </el-button>
                    </el-form-item>
                </el-card>
            </el-form>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ShopEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                goodsId: '',
                goodsName: '',
                goodsImage: [],
                goodsDetailImage: [],
                orderNum: '1',
                taxRate: '0',
                isAvailable: true,
                shopIncomeRate: undefined,
                shareIncomeRate: undefined,
                topIncomeRate: undefined,
                liveShareIncomeRate: undefined,
                anchorIncomeRate: undefined,
                pointRate: undefined,
                storeInitGoodsCatId: [],
                shopTagId: [],
                shopGoodsCatIds: [],
                pointGoodsCatIds: [],
                paymentMethod: 1,
            },
            // 表单校验规则
            formRules: {
                orderNum: {
                    required: true,
                    message: this.$t('pageKey429', '请输入排序号'),
                    trigger: 'blur',
                },
                taxRate: {
                    required: true,
                    message: this.$t('pageKey430', '请输入税率'),
                    trigger: 'blur',
                },
                isAvailable: {
                    required: true,
                    message: this.$t('pageKey431', '请选择是否可用'),
                },
                paymentMethod: [
                    {
                        required: true,
                        message: this.$t('pageKey432', '请选择付款方式'),
                    },
                    {
                        type: 'integer',
                        min: 1,
                        message: this.$t('pageKey432', '请选择付款方式'),
                    },
                ],
            },
            goods: {},
            skuList: [],
            fileBasePath: '',
            defaultPointRate: '',
        };
    },
    computed: {
        formField() {
            const list = [];
            if ((this.formModel.paymentMethod & 1) !== 0) {
                list.push({ key: 'price', name: this.$t('pageKey243', '价格'), required: true });
            }
            if ((this.formModel.paymentMethod & 2) !== 0) {
                list.push({ key: 'pointNum', name: this.$t('pageKey258', '积分'), required: true });
            }
            return list;
        },
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Gd.GoodsAgent.saveGoodsAgent({
                    ...this.formModel,
                    skuListJson: JSON.stringify(this.skuList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Gd.GoodsAgent.getGoodsAgent({ goodsId: this.formModel.goodsId }).then(json => {
                const res = json.data.data;
                this.goods = res.goods;
                this.skuList = res.skuList;
                this.fileBasePath = res.fileBasePath;
                this.defaultPointRate = res.defaultPointRate;
                if (res.goodsAgent) {
                    this.$utils.formModelMerge(this.formModel, res.goodsAgent);
                    if (!this.formModel.paymentMethod) {
                        this.formModel.paymentMethod = 1;
                    }
                    if (!this.formModel.goodsImage || this.formModel.goodsImage.length === 0) {
                        this.syncGoodsImage();
                    }
                    if (!this.formModel.goodsDetailImage || this.formModel.goodsDetailImage.length === 0) {
                        this.syncGoodsDetailImage();
                    }
                    if (!this.formModel.pointRate && this.formModel.pointRate !== 0) {
                        this.formModel.pointRate = undefined;
                    }
                }
            });
        },
        syncGoodsImage() {
            this.formModel.goodsImage = this.goods.goodsImage.split(',').reduce((prev, curr) => {
                prev.push({
                    name: curr,
                    address: this.fileBasePath + curr,
                });
                return prev;
            }, []);
        },
        syncGoodsDetailImage() {
            this.formModel.goodsDetailImage = this.goods.goodsDetailImage
                ? this.goods.goodsDetailImage.split(',').reduce((prev, curr) => {
                      prev.push({
                          name: curr,
                          address: this.fileBasePath + curr,
                      });
                      return prev;
                  }, [])
                : [];
        },
        onBatch(field) {
            // const that = this;
            this.$prompt(`${this.$t('pageKey238', '请输入')} ${field.name}`, this.$t('pageKey42', '温馨提示'), {
                inputValidator: val => {
                    if (!val && field.required) {
                        return `${field.name} ${this.$t('pageKey261', '不能为空')}`;
                    }
                    if (/price|pointNum/.test(field.key) && Number.isNaN(val)) {
                        return this.$t('pageKey433', '请填写');
                    }
                    return true;
                },
            }).then(({ value }) => {
                if (/price|pointNum/.test(field.key)) {
                    value = +value;
                }
                this.skuList.forEach(item => {
                    item[field.key] = value;
                });
            });
        },
    },
    created() {
        this.formModel.goodsId = this.$route.params.goodsId;
        if (this.formModel.goodsId) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
